define("discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invitees/user", ["exports", "discourse/helpers/avatar", "discourse/lib/url", "discourse/lib/utilities", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _avatar, _url, _utilities, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const User = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <a href={{userPath @user.username}} data-user-card={{@user.username}}>
      <span class="user">
        {{avatar @user imageSize="medium"}}
        <span class="username">
          {{formatUsername @user.username}}
        </span>
      </span>
    </a>
  
  */
  {
    "id": "hNjDQEEI",
    "block": "[[[1,\"\\n  \"],[10,3],[15,6,[28,[32,0],[[30,1,[\"username\"]]],null]],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,\"\\n    \"],[10,1],[14,0,\"user\"],[12],[1,\"\\n      \"],[1,[28,[32,1],[[30,1]],[[\"imageSize\"],[\"medium\"]]]],[1,\"\\n      \"],[10,1],[14,0,\"username\"],[12],[1,\"\\n        \"],[1,[28,[32,2],[[30,1,[\"username\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@user\"],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invitees/user.js",
    "scope": () => [_url.userPath, _avatar.default, _utilities.formatUsername],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = User;
});